import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FiltrosContextData {
  listaDeFiltros: string[];
  setListaDeFiltros: React.Dispatch<React.SetStateAction<string[]>>
}

export const useFiltros = () => useContext(FiltrosContext);

export const FiltrosContext = createContext<FiltrosContextData>({} as FiltrosContextData);

export const FiltrosProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [listaDeFiltros, setListaDeFiltros] = useState<string[]>([]);


  return (
    <FiltrosContext.Provider value={{ listaDeFiltros, setListaDeFiltros }}>
      {children}
    </FiltrosContext.Provider>
  );
};
