import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';

export type IErpUsado = 'GVcentris' | 'GVcollege' | 'Nenhum';
export interface IProduto {
  nome: string;
  idProduto: string;
  azureCliente: boolean;
  urlDoBackend: string;
}

export interface IComponents {
  url: string
  cloudflare: boolean
  corsGateway: boolean
  principal?: boolean
}


export interface ITenant {
  _id: string;
  cliente: string;
  idTenant: string;
  subDominio: string;

  ativo: boolean;
  erpUsado: IErpUsado;

  produtosHabilitados: IProduto[];

  loginServiceUrl: string;
  loginServiceIssuer: string;
  loginServiceAudiencia: string;
  loginServiceChavePublica: string;

  apiGvcollegeUrl?: string | null;
  apiGvcollegeVersao?: string;
  portalGVcollegeUrl?: string | null;


  apiGVcentrisUrl?: string | null;
  portalAcademicoGVcentrisUrl?: string | null;
  apiPortalAcademicoGVcentrisUrl?: string | null;
  erpCentrisUrl?: string | null;


  apiCEPKey?: string;
  apiMobileUrl?: string;
}

export interface IGetAllTenants {
  _id: string;
  cliente: string;
  idTenant: string;
  subDominio: string;

  ativo: boolean;
  erpUsado: IErpUsado;
  produtosHabilitados: IProduto[];

  urlDoPainel: string;

  loginServiceUrl: string;
  loginServiceIssuer: string;
  loginServiceAudiencia: string;
  loginServiceChavePublica: string;
  loginServiceVersion?: {
    valor: string,
    dataConsulta: string
  },

  apiGvcollegeUrl?: string;
  apiGvcollegeVersao?: string;
  portalGVcollegeUrl?: string;
  apiGVcollegeVersion?: {
    valor: string,
    dataConsulta: string
  },
  gvcollegeVersion?: {
    valor: string,
    dataConsulta: string
  },

  apiGVcentrisUrl?: string;
  portalAcademicoGVcentrisUrl?: string;
  apiPortalAcademicoGVcentrisUrl?: string;
  apiGVcentrisVersion?: {
    valor: string,
    dataConsulta: string
  },

  apiCEPKey?: string;
  apiMobileUrl?: string;
}

export interface IProdutoHabilitado {
  idProduto: string
  components: IComponents[]
  azureDoCliente: boolean
  urlDoBackend: string
}

export interface INovoProduto {
  idProduto: string,
  components: IComponents[],
  azureCliente: boolean;
  urlDoBackend: string;
  categoriaProduto?: 'moduloCentris' | 'moduloCollege' | 'saaS' | 'onPremise';
  nomeProduto: string
}


export interface ITenantComProdutosHabilitados {
  _id: string;
  cliente: string;
  idTenant: string;
  subDominio: string;

  ativo: boolean;
  erpUsado: IErpUsado;
  produtosHabilitados: INovoProduto[];
  loginServiceUrl: string;
  loginServiceIssuer: string;
  loginServiceAudiencia: string;
  loginServiceChavePublica: string;
  loginServiceVersion?: {
    valor: string,
    dataConsulta: string
  },

  apiGvcollegeUrl?: string;
  apiGvcollegeVersao: string;
  portalGVcollegeUrl?: string;
  apiGVcollegeVersion?: {
    valor: string,
    dataConsulta: string
  },
  gvcollegeVersion?: {
    valor: string,
    dataConsulta: string
  },

  apiGVcentrisUrl?: string;
  portalAcademicoGVcentrisUrl?: string;
  apiPortalAcademicoGVcentrisUrl?: string;
  apiGVcentrisVersion?: {
    valor: string,
    dataConsulta: string
  },
  erpCentrisUrl?: string

  apiCEPKey?: string;
  apiMobileUrl?: string;
  apiMobileVersion?: {
    valor: string,
    dataConsulta: string
  }
}

const getAllTenants = async (
  page: string,
  pageSize: number,
  busca?: string,
  erpUsadoFilter?: string,
  produtos?: string[],
  ativo?: boolean
): Promise<{ data: IGetAllTenants[], totalCount: number }> => {
  try {
    let filterQuery = '';
    if (busca) {
      const escapedFilterValue = busca.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
      filterQuery = `&search=${escapedFilterValue}`;
    }

    const queryProdutos = produtos?.map(produto => `produtos=${produto}`).join('&');

    let ativoFilter = '&ativo=';
    if(ativo === true) ativoFilter = `&ativo=${true}`;
    if(ativo === false) ativoFilter = `&ativo=${false}`;

    const { data } = await Api.get(
      `/Tenants?page=${page}&offset=${pageSize}${filterQuery}&erpUsadoFilter=${erpUsadoFilter}&${queryProdutos}${ativoFilter}`
    );

    if (data) {
      return {
        data,
        totalCount: pageSize,
      };
    }
    throw new ApiException('Não foi possível mostrar os dados');

  } catch (error: any) {
    throw new ApiException(error.data.data.errors.default);
  }
};


const getTenantsById = async (id: string | number): Promise<ITenantComProdutosHabilitados | ApiException> => {
  try {
    const { data } = await Api.get(`/Tenants/${id}`);
    if (data) {
      return data;
    } else {
      return new ApiException('Não foi possível mostrar os dados');
    }

  } catch (error: any) {
    console.error(error);
    return new ApiException(error.data.data.errors.default);
  }
};

const deleteTenantsById = async (id: string | number): Promise<void | ApiException> => {
  try {
    await Api.delete(`/Tenants/${id}`);
    return;
  } catch (error: any) {
    console.error(error);
    return new ApiException(error.data.data.errors.default);
  }
};

const createTenants = async (dados: ITenant): Promise<string | ApiException> => {
  try {
    const { data } = await Api.post('/Tenants', dados);
    if (data) {
      return data;
    }
    return new ApiException('Erro ao criar registro');
  } catch (error: any) {
    console.error(error);

    return new ApiException(
      error.data.data.errors.default ||
      error.data.data.errors.cliente ||
      error.data.data.errors.subDominio ||
      error.data.data.errors.idTenant ||
      error.data.data.errors.produtosHabilitados ||
      error.data.data.apiGVcentrisUrl ||
      error.data.data.apiGvcollegeUrl ||
      error.data.data.apiPortalAcademicoGVcentrisUrl ||
      error.data.data.portalAcademicoGVcentrisUrl ||
      error.data.data.portalGVcollegeUrl
    );
  }
};

const updateTenantsById = async (id: string | number, dados: ITenant): Promise<void | ApiException> => {
  try {
    await Api.put(`/Tenants/${id}`, dados);
    return;

  } catch (error: any) {
    console.error(error.data.data.apiGvcollegeUrl);
    return new ApiException(
      error.data.data.errors?.default ||
      error.data.data.errors?.cliente ||
      error.data.data.errors?.subDominio ||
      error.data.data.errors?.idTenant ||
      error.data.data.errors?.produtosHabilitados ||
      error.data.data.data?.apiGVcentrisUrl ||
      error.data.data.data?.apiGvcollegeUrl ||
      error.data.data.data?.apiPortalAcademicoGVcentrisUrl ||
      error.data.data.data?.portalAcademicoGVcentrisUrl ||
      error.data.data.data?.portalGVcollegeUrl

    );
  }
};

export const TenantsService = {
  updateTenantsById,
  deleteTenantsById,
  getTenantsById,
  getAllTenants,
  createTenants,
};
