import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Feedback } from './Feedback';
import { feedback } from '../../../shared/services';
import InsertCommentIcon from '@mui/icons-material/InsertComment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


export default function ModalFeedback() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" size='small' onClick={handleClickOpen} sx={{display: 'flex', flexDirection: 'column'}}>
        <InsertCommentIcon sx={{marginTop: '5px'}}/>
        Feedback
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Feedback
            idProduto='CAC'
            textoAvaliacao='a Central de Administração de Clientes'
            idTenant='gvdasa-cloud'
            onErro={id => feedback(id, 'error')}
            onEnviarFeedback={id => {
              feedback(id, 'success'),
              handleClose();
            }}
            onFecharModal={() => handleClose()}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}