/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

/** Tradução das valdações YUP */
yup.setLocale({
  mixed: {
    default: 'Campo não é válido',
    required: 'O campo é obrigatório',
    oneOf: (options: any) => `${options.path} precisa ser dos seguintes valores: ${options.values}`,
  },
  string: {
    email: () => 'O campo precisa conter uma email válido',
    max: (options: any) => `O campo pode ter no máximo ${options.max} caracteres`,
    min: (options: any) => `Informe no mínimo ${options.min} caracteres`,
    length: (options: any) => `O campo precisa ter exatamente ${options.length} caracteres`,
  },
  date: {
    max: (options: any) => `A data deve ser menor que ${options.max}`,
    min: (options: any) => `A data deve ser maior que ${options.min}`,
  },
  number: {
    integer: () => 'O campo precisa ter um valor inteiro',
    negative: () => 'O campo precisa ter um valor negativo',
    positive: () => 'O campo precisa ter um valor positivo',
    moreThan: (options: any) => `O campo precisa ter um valor maior que ${options.more}`,
    lessThan: (options: any) => `O campo precisa ter um valor menor que ${options.less}`,
    min: (options: any) => `O campo precisa ter um valor com mais de ${options.min} caracteres`,
    max: (options: any) => `O campo precisa ter um valor com menos de ${options.max} caracteres`,
  },
  boolean: {},
  object: {},
  array: {
    min: () => 'O campo é obrigatório',
  },
});

export * as schemaBuilder from 'yup';
