import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';


const getPermissoes = async (): Promise<{ data: string[] } | ApiException> => {
  try {
    const { data } = await Api.get('Permissoes');
    if (data) {
      return {
        data,
      };
    } else {
      return new ApiException('Não foi possível mostrar as permissões');
    }
  } catch (error: any) {
    console.error(error);
    return new ApiException('Não foi possível mostrar as permissões');

  }
};

export const PermissoesService = {
  getPermissoes
};