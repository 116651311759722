import React, { memo } from 'react';
import { Button, Icon, Divider, Box, useTheme, Skeleton } from '@mui/material';

interface IFerramentasDeDetalheProps {
  aoClicarEmNovo?: () => void;
  aoClicarEmSalvar?: () => void;
  aoClicarEmVoltar?: () => void;
  aoClicarEmApagar?: () => void;
  aoClicarEmSalvarENovo?: () => void;
  aoClicarEmSalvarEFechar?: () => void;
  mostrarBotaoDeNovo?: boolean;
  isBotaoDeNovoLoading?: boolean;
  mostrarBotaoDeSalvar?: boolean;
  isBotaoDeSalvarLoading?: boolean;
  mostrarBotaoDeVoltar?: boolean;
  isBotaoDeVoltarLoading?: boolean;
  mostrarBotaoDeApagar?: boolean;
  isBotaoDeApagarLoading?: boolean;
  mostrarBotaoDeSalvarENovo?: boolean;
  mostrarBotaoDeSalvarEFechar?: boolean;
  isBotaoDeSalvarENovoLoading?: boolean;
  isBotaoDeSalvarEFecharLoading?: boolean;
  desabilitarBotaoDeSalvar?: boolean;
  desabilitarBotaoDeSalvarENovo?: boolean;
  desabilitarBotaoDeSalvarEFechar?: boolean;
  desabilitarBotaoNovo?: boolean;
  desabilitarBotaoApagar?: boolean;
  mostrarDivider?: boolean;



}
export const FerramentasDeDetalhe: React.FC<IFerramentasDeDetalheProps> = memo(({
  mostrarBotaoDeNovo = false,
  mostrarBotaoDeSalvar = false,
  mostrarBotaoDeVoltar = false,
  mostrarBotaoDeApagar = false,
  mostrarBotaoDeSalvarENovo = false,
  mostrarBotaoDeSalvarEFechar = false,
  isBotaoDeNovoLoading = false,
  isBotaoDeSalvarLoading = false,
  isBotaoDeVoltarLoading = false,
  isBotaoDeApagarLoading = false,
  isBotaoDeSalvarENovoLoading = false,
  isBotaoDeSalvarEFecharLoading = false,
  desabilitarBotaoDeSalvar,
  desabilitarBotaoDeSalvarEFechar,
  desabilitarBotaoDeSalvarENovo,
  desabilitarBotaoNovo,
  desabilitarBotaoApagar,
  mostrarDivider,
  aoClicarEmNovo,
  aoClicarEmSalvar,
  aoClicarEmVoltar,
  aoClicarEmApagar,
  aoClicarEmSalvarENovo,
  aoClicarEmSalvarEFechar,
}) => {
  const theme = useTheme();
  return (
    <>
      {(!isBotaoDeSalvarLoading && mostrarBotaoDeSalvar) &&  <Button disabled={desabilitarBotaoDeSalvar} sx={{ color: theme.palette.primary.contrastText }} onClick={aoClicarEmSalvar} startIcon={<Icon>save</Icon>}>Salvar</Button>}
      {isBotaoDeSalvarLoading && <Box display="flex" gap={8} color={theme.palette.primary.contrastText}><Skeleton variant="circular" width={20} /><Skeleton variant="rectangular" width={60} /></Box>}
      {(!isBotaoDeSalvarENovoLoading && mostrarBotaoDeSalvarENovo) && <Button disabled={desabilitarBotaoDeSalvarENovo} sx={{ color: theme.palette.primary.contrastText, marginLeft: theme.spacing(2) }} onClick={aoClicarEmSalvarENovo} startIcon={<Icon>save</Icon>}>Salvar e novo</Button>}
      {(!isBotaoDeSalvarEFecharLoading && mostrarBotaoDeSalvarEFechar) && <Button disabled={desabilitarBotaoDeSalvarEFechar} sx={{ color: theme.palette.primary.contrastText, marginLeft: theme.spacing(2) }} onClick={aoClicarEmSalvarEFechar} startIcon={<Icon>save</Icon>}>Salvar e fechar</Button>}
      {isBotaoDeSalvarENovoLoading && <Box display="flex" gap={8} color={theme.palette.primary.contrastText}><Skeleton variant="circular" width={20} /><Skeleton variant="rectangular" width={60} /></Box>}

      {(!isBotaoDeApagarLoading && mostrarBotaoDeApagar) && <Button disabled={desabilitarBotaoApagar}  sx={{ color: theme.palette.primary.contrastText, marginLeft: theme.spacing(2) }} onClick={aoClicarEmApagar} startIcon={<Icon>delete</Icon>}>Apagar</Button>}
      {isBotaoDeApagarLoading && <Box display="flex" gap={8} color={theme.palette.primary.contrastText}><Skeleton variant="circular" width={20} /><Skeleton variant="rectangular" width={60} /></Box>}
      {(!isBotaoDeNovoLoading && mostrarBotaoDeNovo) && <Button id='gv-navigate' disabled={desabilitarBotaoNovo} sx={{ color: theme.palette.primary.contrastText, marginLeft: theme.spacing(2) }} onClick={aoClicarEmNovo} startIcon={<Icon>add</Icon>}>Novo</Button>}
      {isBotaoDeNovoLoading && <Box display="flex" gap={8} color={theme.palette.primary.contrastText}><Skeleton variant="circular" width={20} /><Skeleton variant="rectangular" width={60} /></Box>}

      {(isBotaoDeVoltarLoading || mostrarBotaoDeVoltar && mostrarDivider) && <Divider color={theme.palette.primary.contrastText} sx={{ backgroundColor: theme.palette.primary.contrastText, marginRight: '1rem', marginLeft: '1rem' }} variant="middle" orientation="vertical" />}

      {(!isBotaoDeVoltarLoading && mostrarBotaoDeVoltar) && <Button id='gv-navigate' sx={{ color: theme.palette.primary.contrastText }} onClick={aoClicarEmVoltar} startIcon={<Icon>reply</Icon>}>Voltar</Button>}
      {isBotaoDeVoltarLoading && <Box display="flex" gap={8} color={theme.palette.primary.contrastText}><Skeleton variant="circular" width={20} /><Skeleton variant="rectangular" width={60} /></Box>}
    </>
  );
});
