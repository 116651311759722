import { Environment } from '../../../environment';

const signOut = async () => {

  try {
    //await Api().get(Environment.BASE_URL + '/SignOut');

    if (Environment.URL_ORIGIN_DEBUG.length > 0)
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    else
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}`;

  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const UsersService = {
  signOut,
};
