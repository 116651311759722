import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import core from '@gvdasa/gv-core';

import { AlertComponent, ConfirmationDialogComponent } from './shared/components';
import { DrawerProvider, ThemesProvider } from './shared/contexts';
import { LayoutBase } from './shared/layouts';
import { AppRoutes } from './routes';
import { PermissoesService } from './shared/services/api/permissoes/PermissoesService';
import { ApiException } from './shared/services/api/ApiException';
import { feedback } from './shared/services';
import { PermissoesProvider } from './shared/contexts/PermissoesContext';
import { Environment } from './shared/environment';
import { FiltroProvider } from './shared/contexts/FiltroContext';
import { FiltrosProvider } from './shared/contexts/IdsProdutosContext';
import { NomeProdutoProvider, FiltroDataProvider } from './shared/contexts/NomeProdutosProvider';


export const App = () => {
  const [listaDePermissoes, setListaDePermissoes] = useState<string[]>([]);

  if (Environment.APP_INSIGHTS_CONNECTION_STRING !== '') {
    core.GVObservable(Environment.APP_INSIGHTS_CONNECTION_STRING);
  }

  useEffect(() => {
    PermissoesService.getPermissoes()
      .then(data => {
        if (data instanceof ApiException) {
          feedback(data.message, 'error');
          return;
        } else {
          setListaDePermissoes(data.data);
        }
      });
  }, []);





  return (

    <>
      <CssBaseline />

      <ThemesProvider>
        <PermissoesProvider permissoesDoUsuario={listaDePermissoes}>
          <BrowserRouter>
            <DrawerProvider>
              <LayoutBase>
                <FiltrosProvider>
                  <NomeProdutoProvider>
                    <FiltroProvider>
                      <FiltroDataProvider>
                        <AppRoutes />
                      </FiltroDataProvider>
                    </FiltroProvider>
                  </NomeProdutoProvider>
                </FiltrosProvider>
                <AlertComponent />
                <ConfirmationDialogComponent />
              </LayoutBase>
            </DrawerProvider>
          </BrowserRouter>
        </PermissoesProvider>
      </ThemesProvider>
    </>
  );
};
