import { memo } from 'react';
import Lottie from 'react-lottie';
import LoadingAnimation from './loading.json';
import Box from '@mui/material/Box';


export const SplashScreen: React.FC = memo(() => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Box display='flex' justifyContent='center' justifyItems='center' height='100vh' >
      <Box display='flex' alignSelf='center'>
        <Lottie options={defaultOptions} height={200} width={200} />
      </Box>
    </Box>

  );
});