export class LocalStorageService {
  static set<T = unknown>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static get<T = unknown>(key: string): T {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : value;
  }
  static remove(key: string) {
    localStorage.removeItem(key);
  }
  static purge() {
    localStorage.clear();
  }
}
