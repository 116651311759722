import React, { useContext, useMemo, ReactNode } from 'react';
import { createContext } from 'react';

export interface IPermissoesDoUsuario {
  permissoesDoUsuario: string[]
  children: ReactNode
}

export const PermissoesDoUsuarioContext = createContext<string[]>({} as string[]);

export const PermissoesProvider: React.FC<IPermissoesDoUsuario> = ({ children, permissoesDoUsuario }) => {
  return (
    <PermissoesDoUsuarioContext.Provider
      value={permissoesDoUsuario}>
      {children}
    </PermissoesDoUsuarioContext.Provider>
  );
};

export const usePermissoes = () => {
  const context = useContext(PermissoesDoUsuarioContext);
  const permissoes = useMemo(() => context, [context]);
  return permissoes;
};

export const useTemPermissao = (permissao: string) => {
  const listaDePermissoes = usePermissoes();

  const temPermissao = useMemo(() => {
    if (listaDePermissoes.toString().includes(permissao)) {
      return false;
    } else {
      return true;
    }

  }, [listaDePermissoes]);

  return temPermissao;
};