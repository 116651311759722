import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, useTheme, TextField, Divider, Toolbar, Box, useMediaQuery, ListItemIcon, Icon, Button } from '@mui/material';
import { NavLink, Link } from 'react-router-dom';

import { UsersService } from './../../services/api';
import { useDrawer } from '../../hooks';
import { GVlogo, LogoGVDASA } from '../../assets';

export const MainDrawer: React.FC = () => {
  const { menuOptions, drawerWidth, isOpen, toggleDrawer, isDrawerPersistent, closeDrawer, isDrawerKeepOpen, isDrawerOpen, openDrawer, toggleDrawerKeepOpen, isDrawerMobile } = useDrawer();
  const [filtro, setFiltro] = useState('');
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [submenuAtivo, setSubmenuAtivo] = useState('');

  const theme = useTheme();
  // const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const pagina = window.location.pathname;
    if (pagina === '/pagina-inicial' || pagina === '/') {
      localStorage.setItem('menus', 'Página inicial');
    }
    setSubmenuAtivo(localStorage.getItem('menus') || 'Página inicial');
  });

  const handleSelectLabel = (label: string) => {
    setSelectedLabel(label);
  };

  const handleSubMenuAtivo = (subLabel: string, label: string) => {
    localStorage.setItem('subMenusCac', subLabel);
    const subMenuAtivado = localStorage.getItem('subMenusCac');
    if (subMenuAtivado) {
      localStorage.setItem('menus', label);
    }
  };

  const handleBack = () => {
    setSelectedLabel(null);
  };


  return (
    <Drawer
      elevation={0}
      open={isDrawerOpen}
      onClose={isDrawerMobile ? closeDrawer : undefined}
      variant={isDrawerMobile ? 'temporary' : 'permanent'}
      onMouseEnter={(isDrawerKeepOpen || isDrawerMobile) ? undefined : openDrawer}
      onMouseLeave={(isDrawerKeepOpen || isDrawerMobile) ? undefined : closeDrawer}
      PaperProps={{
        sx: {
          // my: isDrawerMobile ? 0 : 1,
          // pb: isDrawerMobile ? 0 : 1,
          backgroundColor: 'primary.main',
          transition: isDrawerMobile ? undefined : 'all .2s',
          width: theme.spacing((isDrawerOpen || isDrawerMobile) ? drawerWidth : 8),
        }
      }}
    >
      <Box height="100%" display="flex" flexDirection="column" bgcolor="primary.main" width={theme.spacing(drawerWidth)}>
        {isDrawerOpen && (
          <Toolbar>
            <Link to="/" id='gv-navigate'>
              <Box height={80} width="100%" display="flex" justifyContent="center" justifyItems="center" alignContent="center" >
                <img id='gv-navigate' src={GVlogo} width="100%" alt="Logo gvdasa" style={{ objectFit: 'contain', padding: theme.spacing(2) }} />
              </Box>
            </Link>
          </Toolbar>
        )}
        <Box
          display="flex"
          // flexDirection='column'
          // pl={0}
          component={List}
          // alignContent="flex-start"
          justifyContent="flex-start"
          alignItems='flex-start'
          height={theme.spacing(10)}
          sx={{ boxShadow: `${theme.spacing(0)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0 #00000040` }}
        >
          <ListItem sx={{ paddingLeft: !isDrawerOpen ? 0 : 'default', marginLeft: !isDrawerOpen ? 0 : 'default' }}>
            {!isDrawerOpen && (
              <Box>
                <img id='gv-navigate' src={LogoGVDASA} width="70px" alt="Logo gvdasa" style={{ objectFit: 'contain', padding: theme.spacing(2) }} />
              </Box>
            )}

            {isDrawerOpen && (
              <TextField
                style={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  margin: theme.spacing(0),
                }}
                onChange={(e) => setFiltro(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder={'Buscar no menu...'}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          </ListItem>
        </Box>

        {/* <div style={{ padding: theme.spacing(1), backgroundColor: theme.palette.primary.main, }}>
          <TextField
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
              margin: theme.spacing(0),
            }}
            onChange={(e) => setFiltro(e.target.value)}
            InputLabelProps={{ shrink: true }}
            placeholder={'Buscar no menu...'}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </div> */}
        <Divider />
        <List style={{ overflow: 'auto', flex: 1 }}>
          {selectedLabel === null ? (
            // Mostra os rótulos dos itens de menu
            menuOptions
              .filter((item) => item.label.toLowerCase().indexOf(filtro.toLowerCase()) > -1)
              .map(({ key, label, icon, subMenu, path }) => {
                if (subMenu) {
                  return (
                    <ListItem
                      button
                      key={key}
                      onClick={() => {
                        handleSelectLabel(label);
                      }}
                      sx={{
                        backgroundColor: submenuAtivo === label ? theme.palette.secondary.main : '-moz-initial',
                        color: submenuAtivo === label ? theme.palette.secondary.contrastText : '-moz-initial',
                        '&:hover': {
                          backgroundColor: submenuAtivo === label ? theme.palette.secondary.main : '-moz-initial', // Define a cor de fundo padrão durante o hover
                          color: submenuAtivo === label ? theme.palette.secondary.contrastText : '-moz-initial' // Define a cor do texto padrão durante o hover
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Icon fontSize='medium' sx={{ color: 'secondary.contrastText' }}>{icon}</Icon>
                      </ListItemIcon>
                      <ListItemText sx={{ color: 'secondary.contrastText' }} primary={label} />
                    </ListItem>
                  );
                }
                if (!subMenu) {
                  return (
                    <ListItem
                      button
                      key={key}
                      to={path}
                      component={NavLink}
                      onClick={() => {
                        !isDrawerPersistent ? toggleDrawer : undefined;
                        localStorage.setItem('menus', label);
                        setSubmenuAtivo(localStorage.getItem('menus') || 'Página inicial');
                        localStorage.setItem('subMenusCac', '');
                      }}
                      sx={{
                        backgroundColor: submenuAtivo === label ? theme.palette.secondary.main : '-moz-initial',
                        color: submenuAtivo === label ? theme.palette.secondary.contrastText : '-moz-initial',
                        '&:hover': {
                          backgroundColor: submenuAtivo === label ? theme.palette.secondary.main : '-moz-initial', // Define a cor de fundo padrão durante o hover
                          color: submenuAtivo === label ? theme.palette.secondary.contrastText : '-moz-initial' // Define a cor do texto padrão durante o hover
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Icon fontSize='medium' id='gv-navigate' sx={{ color: 'secondary.contrastText' }}>{icon}</Icon>
                      </ListItemIcon>

                      <ListItemText sx={{ color: 'secondary.contrastText' }} primary={label} />
                    </ListItem>
                  );

                }
              }
              )
          ) : (
            // Mostra os submenus do rótulo selecionado
            <>
              <ListItem
                button
                onClick={handleBack}>
                <ListItemIcon>
                  <Icon fontSize='medium' sx={{ color: 'secondary.contrastText' }}>arrow_left_icon</Icon>
                </ListItemIcon>
                <ListItemText sx={{ color: 'secondary.contrastText' }} primary='Voltar' />
              </ListItem>
              {menuOptions
                .filter((item) => item.label === selectedLabel)
                .map(({ subMenu }) => (
                  subMenu && subMenu.length > 0 && subMenu.map(({ key: subKey, label: subLabel, path: subPath, icon: subIcon }) => (
                    <ListItem
                      button
                      key={subKey}
                      to={subPath}
                      component={NavLink}
                      onClick={() => {
                        !isDrawerPersistent ? toggleDrawer : undefined;
                        handleSubMenuAtivo(subLabel, selectedLabel);
                      }}
                      style={(({ isActive }: any) => (
                        isActive
                          ? {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText,
                          }
                          : {}
                      )) as any}
                    >
                      {subIcon &&
                        <ListItemIcon>
                          <Icon fontSize='medium' sx={{ color: 'secondary.contrastText' }} id='gv-navigate'>{subIcon}</Icon>
                        </ListItemIcon>
                      }
                      <ListItemText sx={{ color: 'secondary.contrastText' }} primary={subLabel} />
                    </ListItem>
                  ))
                ))}
            </>
          )}
        </List>

        <Divider />
        <List style={{ overflow: 'auto' }}>
          <ListItem button onClick={UsersService.signOut}>
            <ListItemIcon>
              <Icon fontSize='medium' sx={{ color: 'secondary.contrastText' }}>logout</Icon>
            </ListItemIcon>
            <ListItemText sx={{ color: 'secondary.contrastText' }} primary="Sair" />
          </ListItem>
        </List>
      </Box>

    </Drawer>
  );
};
