import React, { useEffect, useRef } from 'react';

interface ITesteProps {
  idProduto: string;
  idFuncionalidade?: string;
  onErro?: (id: string) => void;
  onEnviarFeedback?: (id: string) => void;
  onFecharModal?: (id: string) => void;
  idTenant?: string;
  token?: string;
  textoAvaliacao?: string;
}
export const Feedback: React.FC<ITesteProps> = ({
  idProduto,
  onErro,
  onEnviarFeedback,
  onFecharModal,
  idFuncionalidade,
  idTenant,
  token,
  textoAvaliacao
}) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const onEnviarFeedbackListener = (event: any) => onEnviarFeedback && onEnviarFeedback(event.detail);
    const error = (event: any) => onErro && onErro(event.detail);
    const fecharModal = (event: any) => onFecharModal && onFecharModal(event.detail);
    const ref = elementRef.current;
    if (ref) {
      ref.addEventListener('enviarFeedback', onEnviarFeedbackListener);
      ref.addEventListener('erro', error);
      ref.addEventListener('fecharModal', fecharModal);
      return () => {
        ref?.removeEventListener('enviarFeedback', onEnviarFeedbackListener);
        ref?.removeEventListener('erro', error);
        ref?.removeEventListener('fecharModal', fecharModal);
      };
    }
  }, [onErro, onEnviarFeedback, onFecharModal]);

  return (
    React.createElement('feedback-component', {
      ref: elementRef,
      'id-produto': idProduto,
      'id-funcionalidade': idFuncionalidade,
      'id-tenant': idTenant,
      'token': token,
      'texto-avaliacao': textoAvaliacao
    })
  );

};
