import React, { useState, ReactNode } from 'react';
import { createContext } from 'react';

export interface INomeProdutoProps {
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  selectedErp: string;
  setSelectedErp: React.Dispatch<React.SetStateAction<string>>;
  selectAtivo: boolean | undefined;
  setSelectAtivo: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}


export interface IFiltroDataProps {
  dataInicial: string;
  setDataInicial: React.Dispatch<React.SetStateAction<string>>;
  dataFinal: string;
  setDataFinal: React.Dispatch<React.SetStateAction<string>>;
}

export const NomeProdutoContext = createContext<INomeProdutoProps>({
  selectedItems: [],
  setSelectedItems: () => [],
  selectedErp: 'todos',
  setSelectedErp: () => '',
  selectAtivo: true,
  setSelectAtivo: () => undefined

});

export const NomeProdutoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedErp, setSelectedErp] = useState<string>('todos');
  const [selectAtivo, setSelectAtivo] = useState<boolean | undefined>(true);


  return (
    <NomeProdutoContext.Provider value={{
      selectedItems,
      setSelectedItems,
      selectedErp,
      setSelectedErp,
      selectAtivo,
      setSelectAtivo
    }}>
      {children}
    </NomeProdutoContext.Provider>
  );
};

export const FiltroDataContext = createContext<IFiltroDataProps>({
  dataFinal: '',
  setDataInicial: () => '',
  dataInicial: '',
  setDataFinal: () => ''
});

export const FiltroDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dataInicial, setDataInicial] = useState<string>('');
  const [dataFinal, setDataFinal] = useState<string>('');

  return (
    <FiltroDataContext.Provider value={{ dataInicial, setDataInicial, dataFinal, setDataFinal }}>
      {children}
    </FiltroDataContext.Provider>
  );
};



