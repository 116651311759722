import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


const SplashScreen = React.lazy(() => import('../shared/components')
  .then(({ SplashScreen }) => ({
    default: SplashScreen
  })));


const ListagemLicenciamentosPage = React.lazy(() => import('../pages/licenciamentos/ListagemLicenciamentosPages')
  .then(({ ListagemLicenciamentosPage }) => ({
    default: ListagemLicenciamentosPage
  })));

const DetalheLicenciamentosPage = React.lazy(() => import('../pages/licenciamentos/DetalheLicenciamentosPage')
  .then(({ DetalheLicenciamentosPage }) => ({
    default: DetalheLicenciamentosPage
  })));

const ListagemTenantsPage = React.lazy(() => import('../pages/tenants/ListagemTenantsPage')
  .then(({ ListagemTenantsPage }) => ({
    default: ListagemTenantsPage
  }))
);

const TelemetriaGVCollegePage = React.lazy(() => import('../pages/telemetria-gvcollege/TelemetriaGvCollegePage')
  .then(({ TelemetriaGvCollegePage }) => ({
    default: TelemetriaGvCollegePage
  }))
);

const RedirecionamentoDeTopicosPage = React.lazy(() => import('../pages/topic-manager/RedirecionamentoDeTopicosPage').then(({ RedirecionamentoDeTopicosPage }) => ({
  default: RedirecionamentoDeTopicosPage
})));

const TopicosDeTenantsPage = React.lazy(() => import('../pages/topic-manager/TopicosDeTenantsPage').then(({ TopicosDeTenantsPage }) => ({
  default: TopicosDeTenantsPage
})));


const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard').then(({ Dashboard }) => ({
  default: Dashboard
})));

const DetalheTenantsPage = React.lazy(() => import('../pages/tenants/DetalheTenantsPage').then(({ DetalheTenantsPage }) => ({
  default: DetalheTenantsPage
})));

const ConsultaProdutosHabilitados = React.lazy(() => import('../pages/tenants/components/consulta-produtos-habilitados/ConsultaProdutosHabilitados').then(({ ConsultaProdutosHabilitados }) => ({
  default: ConsultaProdutosHabilitados
})));

const ListagemProdutosPage = React.lazy(() => import('../pages/produtos/ListagemProdutosPage').then(({ ListagemProdutosPage }) => ({
  default: ListagemProdutosPage
})));


const DetalheProdutosPage = React.lazy(() => import('../pages/produtos/DetalheProdutosPage').then(({ DetalheProdutosPage }) => ({
  default: DetalheProdutosPage
})));


const ConsultaFeedbacks = React.lazy(() => import('../pages/consulta-feedbacks/ConsultaFeedbacks').then(({ ConsultaFeedbacks }) => ({
  default: ConsultaFeedbacks
})));

const DetalhesFeedback = React.lazy(() => import('../pages/consulta-feedbacks/DetalhesFeedback').then(({ DetalhesFeedback }) => ({
  default: DetalhesFeedback
})));

const ListagemDeRelatorios = React.lazy(() => import('../pages/relatorios/ListagemDeRelatorios').then(({ ListagemDeRelatorios }) => ({
  default: ListagemDeRelatorios
})));

const TabsTelemetria = React.lazy(() => import('../pages/telemetria/TabsTelemetria').then(({ TabsTelemetria }) => ({
  default: TabsTelemetria
})));


const ListagemTermosPage = React.lazy(() => import('../pages/termos/ListagemTermosPage').then(({ ListagemTermosPage }) => ({
  default: ListagemTermosPage
})));

const DetalheTermosPage = React.lazy(() => import('../pages/termos/DetalheTermosPage').then(({ DetalheTermosPage }) => ({
  default: DetalheTermosPage
})));

const ListagemCategoriasPage = React.lazy(() => import('../pages/termos/ListagemCategoriasPage').then(({ ListagemCategoriasPage }) => ({
  default: ListagemCategoriasPage
})));


const DetalheCategoriasPage = React.lazy(() => import('../pages/termos/DetalheCategoriasPage').then(({ DetalheCategoriasPage }) => ({
  default: DetalheCategoriasPage
})));

const ListagemDeFuncoes = React.lazy(() => import('../pages/relatorios/funcoes/ListagemDeFuncoes').then(({ ListagemDeFuncoes }) => ({
  default: ListagemDeFuncoes
})));


const DetalheFuncoesPage = React.lazy(() => import('../pages/relatorios/funcoes/DetalheFuncoesPage').then(({ DetalheFuncoesPage }) => ({
  default: DetalheFuncoesPage
})));


export const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={
      <SplashScreen />
    }>
      <Routes>
        <Route path='/pagina-inicial' element={<Dashboard />} />
        <Route path={'/tenants'} element={<ListagemTenantsPage />} />
        <Route path={'/tenants/:page'} element={<ListagemTenantsPage />} />
        <Route path={'/tenants/detalhe/:id'} element={<DetalheTenantsPage />} />
        <Route path={'/tenants/:page/:busca'} element={<ListagemTenantsPage />} />

        <Route path={'tenants/consulta/:id'} element={<ConsultaProdutosHabilitados />} />

        <Route path={'/produtos'} element={<ListagemProdutosPage />} />
        <Route path={'/produtos/:page'} element={<ListagemProdutosPage />} />
        <Route path={'/produtos/detalhe/:id'} element={<DetalheProdutosPage />} />
        <Route path={'/produtos/:page/:busca'} element={<ListagemProdutosPage />} />
        <Route path={'/licenciamentos/detalhe/:id'} element={<DetalheLicenciamentosPage />} />
        <Route path={'/licenciamentos/:page/:busca'} element={<ListagemLicenciamentosPage />} />
        <Route path={'/licenciamentos'} element={<ListagemLicenciamentosPage />} />


        <Route path={'/telemetria-gvcollege'} element={<TelemetriaGVCollegePage />} />


        <Route path={'/consulta-feedbacks'} element={<ConsultaFeedbacks />} />
        <Route path={'/resultado-feedbacks-tenant/:idTenant'} element={<DetalhesFeedback />} />
        <Route path={'/resultado-feedbacks-produto/:idProduto'} element={<DetalhesFeedback />} />

        <Route path={'/relatorios'} element={<ListagemDeRelatorios />} />


        {/* <Route path={'/consulta-telemetria'} element={<Telemetria />} /> */}
        <Route path={'/consulta-telemetria'} element={<TabsTelemetria />} />

        <Route path={'/termos-padrao'} element={<ListagemTermosPage />} />
        <Route path={'/termos-padrao/detalhe/:id'} element={<DetalheTermosPage />} />
        <Route path={'/categorias-de-termo'} element={<ListagemCategoriasPage />} />
        <Route path={'/categorias-de-termo/detalhe/:id'} element={<DetalheCategoriasPage />} />

        <Route path={'/funcoes'} element={<ListagemDeFuncoes />} />
        <Route path={'/funcoes/detalhe/:id'} element={<DetalheFuncoesPage />} />

        <Route path={'/redirecionamento-topicos'} element={<RedirecionamentoDeTopicosPage />} />

        <Route path={'/topicos-tenants'} element={<TopicosDeTenantsPage />} />


        {/* ROBO - Nova linha acima [rotas] */}

        <Route path='/' element={<Navigate to='/pagina-inicial' />} />
        <Route path='*' element={<Navigate to='/pagina-inicial' />} />
      </Routes>
    </Suspense>
  );
};
