import React, { memo } from 'react';
import { Menu } from '@mui/icons-material';
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Paper,
  Box,
  useTheme,
  Theme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';


import { useDrawer } from '../hooks';
import ModalFeedback from '../../pages/tenants/components/ModalFeedback';

const useClasses = makeStyles((theme: Theme) => ({
  toolbar: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItens: 'center',
    width: '100%'
  }
}));



interface PageLayoutProps {
  /** Titulo da página atual */
  title: string;
  /** Ferramentas que estarão no app bar */
  tools?: React.ReactNode;
  /** Alinha o layout flex contido no interior do componente */
  alignTools?: 'end' | 'start';
  /** Usado para adiconar uma barra extra de navegação */
  navigation?: React.ReactNode;
  /** Alinha o layout flex contido no interior do componente */
  alignNavigation?: 'end' | 'start';
  /** Conteúdo da página ex: Forms, Inputs, Labels, Grids... */
  children?: React.ReactNode;
}

/**
 * Usado para definir uma estrutura básica para todas as páginas
 * @param title Titulo da página atual
 * @param tools Ferramentas que estarão no app bar
 * @param alignTools alinhamento das ferramentas
 * @param children Conteúdo da página ex: Forms, Inputs, Labels, Grids...
 */
export const PageLayout: React.FC<PageLayoutProps> = memo(({ title, alignTools, alignNavigation, navigation, tools, children }) => {
  const { toggleDrawer, drawerWidth, isDrawerMobile, isDrawerOpen } = useDrawer();

  const classes = useClasses();
  const theme = useTheme();

  return (
    <>
      <AppBar
        color="default"
        sx={{
          transition: 'all .2s',
          display: 'flex',
          width: '100vw',
          paddingLeft: isDrawerOpen ? 0 : 2,
          justifyContent: 'center',
          marginLeft: isDrawerMobile
            ? 0
            : theme.spacing(isDrawerOpen ? drawerWidth : 2)
        }}>
        <Box
          display="flex"
          height={80}
          overflow='auto'
          // width='100%'
          // bgcolor={theme.palette.primary.main}
          paddingLeft={isDrawerOpen ? 2 : 5}
          justifyContent='space-between'
          sx={{ transition: 'all .2s' }}
          marginLeft={
            isDrawerMobile
              ? 0
              : theme.spacing(isDrawerOpen ? drawerWidth : 2)}
        >
          <Toolbar className={classes.toolbar}>
            {isDrawerMobile &&
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggleDrawer}
                aria-label="Abre o menu lateral"
              ><Menu /></IconButton>
            }
            <Typography variant="h6">{title}</Typography>
            <ModalFeedback />
          </Toolbar>
        </Box>

        <Box // Barra de botões de acesso
          padding={1}
          height={56}
          display="flex"
          paddingLeft={isDrawerOpen ? 4 : 8}
          paddingRight={2}
          boxShadow="none"
          borderRadius='0px'
          component={Paper}
          alignItems="center"
          color="textPrimary"
          sx={{ borderRadius: '0px', transition: 'all .2s' }}
          bgcolor={theme.palette.primary.main}
          justifyContent={`flex-${alignNavigation}`}
          marginLeft={
            isDrawerMobile
              ? 0
              : theme.spacing(isDrawerOpen ? drawerWidth : 2)}
        >{tools}</Box>

        {/* {navigation &&
          <Box // Barra de navegação
            padding={8}
            display="flex"
            width='100vw'
            height='100vh'
            paddingLeft={1}
            paddingRight={1}
            boxShadow="none"
            borderRadius='0px'
            component={Paper}
            alignItems="center"
            bgcolor='red'
            justifyContent={`flex-${alignNavigation}`}
            sx={{ transition: 'all .2s' }}
            marginLeft={
              isDrawerMobile
                ? 0
                : theme.spacing(isDrawerOpen ? drawerWidth : 2)}
          >{navigation}</Box>
        } */}
      </AppBar>

      <Box
        padding={2}
        paddingTop={20}
        display="flex"
        flexDirection="column"
        justifyContent='center'
        sx={{ transition: 'all .2s' }}
        marginLeft={
          isDrawerMobile
            ? 0
            : theme.spacing(isDrawerOpen ? drawerWidth : 5)}
      >
        <Paper variant="outlined" component={Box} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {children}
        </Paper>
      </Box>
    </>
  );
});
