import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Checkbox, FormControlLabel, Box } from '@mui/material';

import { ImagemDeModalDeAmbientes } from '../../assets';

export const ModalDeNovidades: React.FC = () => {
  const [isChecked, setIsChecked] = useState(localStorage.getItem('desativarAvisosDeAtualizacoes') === 'true');
  const [isOpen, setIsOpen] = useState(!isChecked);

  useEffect(() => {
    localStorage.setItem('desativarAvisosDeAtualizacoes', String(isChecked));
  }, [isChecked]);

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogContent>
        <Box width="100%" display="flex" justifyContent="center">
          <img src={ImagemDeModalDeAmbientes} alt="" />
        </Box>

        <Typography variant="h4" align="center">Novidades de atualização</Typography>

        <br />
        <Typography>
          <li>Foi adicionado ícones no menu lateral.</li>
          <li>Foi melhorado a experiência no formulário de criação de tenants.</li>
          <br />
        </Typography>

        <br />
        <br />

        <FormControlLabel
          label="Não receber mais avisos dessa natureza."
          control={<Checkbox checked={isChecked} />}
          onChange={() => setIsChecked(!isChecked)}
        />
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => setIsOpen(false)}
        >Entendi</Button>
      </DialogActions>
    </Dialog>
  );
};
