import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ITenant } from '../services/api';

interface FiltroContextData {
  filtro: ITenant[];
  setFiltro: (filtro: ITenant[]) => void;

}

const FiltroContext = createContext<FiltroContextData>({
  filtro: [],
  setFiltro: () => '',
});

export const useFiltro = () => useContext(FiltroContext);

export const FiltroProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [filtro, setFiltro] = useState<ITenant[]>([]);


  return (
    <FiltroContext.Provider value={{ filtro, setFiltro}}>
      {children}
    </FiltroContext.Provider>
  );
};
