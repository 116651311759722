import React, { ReactNode } from 'react';
import {
  Typography,
  Divider,
  useTheme,
  Container,
  Box,
} from '@mui/material';



interface ISectionProps {
  variant?:
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'overline'
  | 'srOnly'
  | undefined;
  divider?: boolean;
  padding?: boolean;
  margin?: boolean;
  title?: string;
  id?: string;
  children: ReactNode;
  /**
   * Adiciona ação no header do section exemplo: button para ação.
   */
  action?: React.ReactChild;
}
export const Section: React.FC<ISectionProps> = ({
  id,
  title,
  children,
  margin,
  padding,
  divider = true,
  action,
}) => {
  const theme = useTheme();

  return (
    <Container
      id={id}
      style={{
        width: 'auto',
        margin: theme.spacing(margin ? 2 : 0),
        padding: theme.spacing(padding ? 2 : 0),
      }}
    >
      <Box display="flex" justifyContent="space-between">
        {title && <Typography variant='h6'>{title}</Typography>}
        {action}
      </Box>
      {divider && title && (
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
          }}
        />
      )}
      {children}
    </Container>
  );
};
