import _GVlogo from './Logo.png';
import _ImagemDeNovaAba from './ImagemDeNovaAba.png';
import _ImagemDeBoasVindas from './ImagemDeBoasVindas.png';
import _ImagemDeModalDeAmbientes from './ImagemDeModalDeAmbientes.png';
import _ImagemLogoGV from './ImagemLogoGV.png';

export const ImagemDeModalDeAmbientes = _ImagemDeModalDeAmbientes;
export const ImagemDeBoasVindas = _ImagemDeBoasVindas;
export const ImagemDeNovaAba = _ImagemDeNovaAba;
export const GVlogo = _GVlogo;
export const LogoGVDASA = _ImagemLogoGV;
