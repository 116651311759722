import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Checkbox, FormControlLabel, Box } from '@mui/material';

import { ImagemDeNovaAba } from '../../assets';

interface IModalDicaDeNovoAbaProps {
  isOpen: boolean;
  onConfirm?: () => void;
}
export const ModalDicaDeNovoAba: React.FC<IModalDicaDeNovoAbaProps> = ({ isOpen, onConfirm }) => {
  const [isChecked, setIsChecked] = useState(localStorage.getItem('desativarAvisosDeAberturaDeNovaAba') === 'true');

  useEffect(() => {
    localStorage.setItem('desativarAvisosDeAberturaDeNovaAba', String(isChecked));
  }, [isChecked]);

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogContent>
        <Box width="100%" display="flex" justifyContent="center">
          <img src={ImagemDeNovaAba} alt="" />
        </Box>

        <Typography variant="h4" align="center">Você será direcionado para outra aba</Typography>

        <br />
        <br />

        <Typography>
          Estamos atualizando o ERP e começaremos a trabalhar com abas do seu navegador.
        </Typography>


        <br />

        <FormControlLabel
          label="Não receber mais avisos dessa natureza."
          control={<Checkbox checked={isChecked} />}
          onChange={() => setIsChecked(!isChecked)}
        />
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >Entendi</Button>
      </DialogActions>
    </Dialog>
  );
};
