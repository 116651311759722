import React, { memo, useCallback } from 'react';
import { Box, TextField, Button, Icon, useTheme, IconButton, Tooltip } from '@mui/material';
import { Environment } from '../../environment';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterIcon from '@mui/icons-material/Filter';
import CheckIcon from '@mui/icons-material/Check';


interface IFerramentasDaListagemProps {
  valorDaBusca?: string
  textoBotaoDeNovo?: string
  aoClicarEmNovo?: () => void
  esconderBotaoDeNovo?: boolean
  esconderCampoDeBusca?: boolean
  aoMudarBusca?: (valorDaBusca: string) => void
  AbriModalDeFiltro?: () => void
  mostrarBotaoDeFiltro?: boolean
  umItemFiltrado?: boolean
  doisItemsFiltrados?: boolean
  tresItemsFiltrados?: boolean
  quatroItemsFiltrados?: boolean
  cincoItemsFiltrados?: boolean
  seisItemsFiltrados?: boolean
  seteItemsFiltrados?: boolean
  oitoItemsFiltrados?: boolean
  noveItemsFiltrados?: boolean
  maisDeNoveItemsFiltrados?: boolean
  filtroAtivado?: boolean
  exportarCSV?: boolean
  handleExportarCSV?: () => void
  disableExportarCSV?: boolean
  erpUsado?: string;
  mostrarBotaoErpUsado?: boolean;
  listaDeFiltros?: string[];
  sincronizarRelatorios?: boolean;
  handleSincronizarRelatorios?: () => void;
  disableSincronizarRelatorios?: boolean;
  ativo?: boolean;
}
// eslint-disable-next-line react/display-name
export const FerramentasDaListagem: React.FC<IFerramentasDaListagemProps> = memo(({
  valorDaBusca,
  esconderCampoDeBusca,
  esconderBotaoDeNovo,
  textoBotaoDeNovo = 'Novo',
  aoMudarBusca,
  aoClicarEmNovo,
  AbriModalDeFiltro,
  mostrarBotaoDeFiltro,
  filtroAtivado,
  exportarCSV,
  handleExportarCSV,
  disableExportarCSV,
  erpUsado,
  mostrarBotaoErpUsado,
  listaDeFiltros,
  sincronizarRelatorios,
  disableSincronizarRelatorios,
  handleSincronizarRelatorios,
  ativo
}) => {
  const theme = useTheme();

  const listaFiltrada = useCallback(() => {
    if ((listaDeFiltros && listaDeFiltros.length > 0) || erpUsado !== 'todos' || ativo !== undefined) {
      return true;
    } else {
      return false;
    }

  }, [listaDeFiltros, erpUsado, ativo]);


  return (
    <>
      <Box flex={1} display='flex' alignItems='center' width='100vw'>
        {!esconderCampoDeBusca && (
          <Box display='flex' flexDirection='row' alignItems='center'>
            <TextField
              variant="outlined"
              value={valorDaBusca}
              InputLabelProps={{ shrink: true }}
              placeholder={Environment.INPUT_DE_BUSCA}
              onChange={e => aoMudarBusca && aoMudarBusca(e.target.value)}
              sx={{
                margin: theme.spacing(0),
                backgroundColor: theme.palette.background.paper,
              }}
            />
            {mostrarBotaoDeFiltro && (
              <Box
                display='flex'
                alignItems='center'
                ml={2}
              >
                <IconButton
                  sx={{
                    color: theme.palette.primary.contrastText,
                    marginLeft: '0.3rem',
                    '&:hover': {
                      backgroundColor: 'transparent', // Remova o background no hover
                    },
                    '&:focus': {
                      backgroundColor: 'transparent', // Remova o background no focus
                    },
                    '&:active': {
                      backgroundColor: 'transparent', // Remova o background no active
                    },
                  }}
                  onClick={AbriModalDeFiltro}
                  disableTouchRipple
                  disableFocusRipple
                >
                  <Box
                    display='flex'
                    alignContent='flex-start'
                    gap={0.5}

                  >
                    <FilterListIcon fontSize='medium' />
                    <FilterIcon sx={{ fontSize: '14px', display: listaFiltrada() ? 'flex' : 'none' }} />

                  </Box>
                </IconButton>
              </Box>
            )}
          </Box>
        )}

        {mostrarBotaoDeFiltro && esconderCampoDeBusca && (
          <Box
            display='flex'
            alignItems='center'
          // margin={2}
          >
            <IconButton
              sx={{
                color: theme.palette.primary.contrastText,
                marginLeft: '0.3rem',
              }}
              onClick={AbriModalDeFiltro}
            >
              <FilterListIcon />
            </IconButton>
            {filtroAtivado && (
              <Tooltip title='Filtro ativo' placement='right'>
                <FilterIcon fontSize='small'
                  sx={{
                    color: theme.palette.primary.contrastText,
                    marginLeft: '0.3rem',
                  }} />
              </Tooltip>
            )}
          </Box>
        )}

      </Box >
      {!sincronizarRelatorios && (
        <Button
          disabled={esconderBotaoDeNovo}
          onClick={aoClicarEmNovo}
          startIcon={<Icon sx={{ display: esconderBotaoDeNovo ? 'none' : 'flex' }}>add</Icon>}
          sx={{ color: theme.palette.primary.contrastText }}
        >{textoBotaoDeNovo}</Button>
      )
      }
      {exportarCSV && (
        <Box width={200} display='flex' justifyContent='center'>
          <Button
            disabled={disableExportarCSV}
            variant='contained'
            onClick={handleExportarCSV}
          >Exportar CSV</Button>
        </Box>
      )}
      {
        sincronizarRelatorios && (
          <Box width={200} display='flex' justifyContent='center'>
            <Button
              disabled={disableSincronizarRelatorios}
              variant='contained'
              onClick={handleSincronizarRelatorios}
              startIcon={<CheckIcon />}
            >Sincronizar</Button>
          </Box>
        )
      }
    </>
  );
});
