import { GridLocaleText, GRID_DEFAULT_LOCALE_TEXT } from '@mui/x-data-grid';

import { Environment } from '../environment';

export const traducaoComponenteDataGrid: GridLocaleText = {
  ...GRID_DEFAULT_LOCALE_TEXT,
  // Root
  noRowsLabel: Environment.LISTAGEM_VAZIA,
  noResultsOverlayLabel: Environment.LISTAGEM_VAZIA,
  // errorOverlayDefaultLabel: Environment.LISTAGEM_VAZIA,

  // Density selector toolbar button text
  toolbarDensity: 'Density',
  toolbarDensityLabel: 'Density',
  toolbarDensityCompact: 'Compact',
  toolbarDensityStandard: 'Standard',
  toolbarDensityComfortable: 'Comfortable',

  // Columns selector toolbar button text
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar colunas',

  // Filters toolbar button text
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Esconder filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Baixar como CSV',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Buscar coluna',
  columnsPanelTextFieldPlaceholder: 'Título da coluna',
  columnsPanelDragIconLabel: 'Reordenar colunas',
  columnsPanelShowAllButton: 'Mostrar tudo',
  columnsPanelHideAllButton: 'Esconder tudo',

  // Filter panel text
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Apagar',
  // filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor para filtrar',

  // Filter operators text
  filterOperatorContains: 'Contém',
  filterOperatorEquals: 'Igual',
  filterOperatorStartsWith: 'Começa com',
  filterOperatorEndsWith: 'Termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'Não é',
  filterOperatorAfter: 'is after',
  filterOperatorOnOrAfter: 'is on or after',
  filterOperatorBefore: 'is before',
  filterOperatorOnOrBefore: 'is on or before',

  // Filter values text
  filterValueAny: 'Qualquer',
  filterValueTrue: 'Verdadeiro',
  filterValueFalse: 'Falso',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar ou esconder colunas',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Remove ordenação',
  columnMenuSortAsc: 'Ordenar por CRES',
  columnMenuSortDesc: 'Ordenar por DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) => count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) => count !== 1
    ? `${count.toLocaleString()} linhas selecionadas`
    : `${count.toLocaleString()} linha selecionada`,

  // Total rows footer text
  footerTotalRows: 'Total de colunas:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Selecionar',

  // Boolean cell text
  booleanCellTrueLabel: 'Verdadeiro',
  booleanCellFalseLabel: 'Falso',
  filterOperatorIsEmpty: '',
  filterOperatorIsNotEmpty: '',
  MuiTablePagination: {
    labelRowsPerPage: 'Linhas por página',
    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `${to} de`}`,
    nextIconButtonProps: {
      children: 'Próxima página',
    },
    backIconButtonProps: {
      children: 'Página anterior',
    },
  }
};
