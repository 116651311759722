import React, { useEffect, useState, useContext, createContext, useCallback, ReactNode } from 'react';
import { ThemeProvider, Theme } from '@mui/material/styles';

import { LightTheme } from './../themes';

interface IThemeContextData {
  toggleTheme(): void;
  isDark: boolean;
  theme: Theme;
}

export const useThemesContext = () => useContext(ThemeContext);

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

export const ThemesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    // const isDark = LocalStorageService.isDarkTheme();
    setThemeData(oldState => ({
      ...oldState,
      theme: /* isDark ? DarkTheme :  */LightTheme,
      isDark: false,
    }));
  }, []);

  const handleToggleTheme = useCallback(() => {
    // LocalStorageService.toggleTheme();
    setThemeData(oldState => ({
      ...oldState,
      theme: /* oldState.isDark ?  */LightTheme/*  : DarkTheme */,
      isDark: /* !oldState.isDark */false,
    }));
  }, []);

  const [themeData, setThemeData] = useState<IThemeContextData>({
    toggleTheme: handleToggleTheme,
    theme: LightTheme,
    isDark: false,
  });

  return (
    <ThemeContext.Provider value={themeData}>
      <ThemeProvider theme={themeData.theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
